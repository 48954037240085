const ServicesData =[
    {
        id:1,
        title: 'Travel Experts',
        desc:'We’ll pick up from airport while you comfy on your ride mus entue habitant.',
        cover:require("../../Assets/ImagesNew/Lewis-and-Clark-Tours-1-removebg-preview.png"),
    },
    {
        id:2,
        title: 'Authentic Experiences',
        desc:'We’ll pick up from airport while you comfy on your ride mus entue habitant.',
        cover:require("../../Assets/ImagesNew/Lewis-and-Clark-Tours-2-removebg-preview.png"),
    },
    {
        id:3,
        title: 'Exceptional Service',
        desc:'We’ll pick up from airport while you comfy on your ride mus entue habitant.',
        cover:require("../../Assets/ImagesNew/Lewis-and-Clark-Tours-3-removebg-preview.png"),
    },
    {
        id:4,
        title: 'Unconditional Support',
        desc:'We’ll pick up from airport while you comfy on your ride mus entue habitant.',
        cover:require("../../Assets/ImagesNew/Lewis-and-Clark-Tours-4-removebg-preview.png"),
    },
    {
        id:5,
        title: 'South India Tours',
        desc:'We’ll pick up from airport while you comfy on your ride mus entue habitant.',
        cover:require("../../Assets/ImagesNew/southindia.jpg"),
    },
    {
        id:6,
        title: 'South India Tours',
        desc:'We’ll pick up from airport while you comfy on your ride mus entue habitant.',
        cover:require("../../Assets/ImagesNew/southindia.jpg"),
    },
    
]

export default ServicesData;